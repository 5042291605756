import { defineComponent, inject } from 'vue';
import Header from '@/components/Header.vue';
import LeftFrame from '@/components/personal/LeftFrame.vue';
export default defineComponent({
  name: 'MemberCenterLayout',
  components: {
    HeaderComponent: Header,
    LeftFrame: LeftFrame
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    return {
      isMobile: isMobile
    };
  }
});
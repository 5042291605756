import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2713218b"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "wrapper maxWidth"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_left_frame = _resolveComponent("left-frame");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_header_component), _createElementVNode("section", _hoisted_2, [_renderSlot(_ctx.$slots, "header", {}, undefined, true), _createElementVNode("main", null, [_withDirectives(_createVNode(_component_left_frame, null, null, 512), [[_vShow, !_ctx.isMobile]]), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])])]);
}
import { defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
export default defineComponent({
  name: 'DeleteAccountPopup',
  components: {
    DialogComponent: Dialog
  },
  props: ['isOpenPopup', 'onClosePopup'],
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');

    var mailTo = function mailTo() {
      window.location.href = 'mailto:service@Reelax.com';
    };

    return {
      t: t,
      isMobile: isMobile,
      mailTo: mailTo
    };
  }
});